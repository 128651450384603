var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fetched)?_c('div',[_c('div',{staticClass:"h-6 flex items-center",class:{
      'h-6 text-sm': !_vm.small,
      'h-4 text-xs': _vm.small,
    }},[_c('div',{staticClass:"rounded-full mr-2",class:{
        'w-3 h-3': !_vm.small,
        'w-2 h-2': _vm.small,
        [_vm.color]: true,
      }}),_c('div',{domProps:{"textContent":_vm._s(_vm.text)}})]),(_vm.showDetails && _vm.items.length > 1)?_c('div',{staticClass:"ml-4"},_vm._l((_vm.items),function(detail){return _c('div',{key:detail.name,staticClass:"h-4 flex items-center"},[(detail.totalAvailable >= _vm.qtyIncCart * detail.quantity)?_c('svg',{staticClass:"fill-current w-4 h-4 mr-1 text-green-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M18.9,8.1L9,18L4.05,13.05L4.76,12.34L9,16.59L18.19,7.39L18.9,8.1Z"}})]):_c('svg',{staticClass:"fill-current w-4 h-4 mr-1 text-red-600",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"}})]),_c('div',{staticClass:"text-xs",domProps:{"textContent":_vm._s(detail.name)}})])}),0):_vm._e(),(_vm.showNotify && _vm.totalAvailable === 0)?_c('NotifyInStock',{staticClass:"mt-4",attrs:{"listingId":_vm.variation.id}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }