<template>
  <article
    style="
      min-height: calc(90vh - 120px);
      scroll-padding-top: calc(64px + 1.5rem + 0.875rem + 1rem);
    "
    class="relative bg-white dark:bg-gray-900"
  >
    <div v-if="loading" class="absolute inset-0 flex justify-center align-center">
      <div
        class="m-auto spinner x-large text-sm pt-16 dark:text-white"
        v-text="`${$t('common.loading')}...`"
      />
    </div>
    <div v-else class="w-full h-full">
      <NotFound v-if="!product" />
      <div v-else>
        <div class="py-6 mx-auto px-6 lg:max-screen-md xl:max-w-screen-lg xxl:max-w-screen-xl">
          <Breadcrumb :categories="product.categories" :productName="product.name" />
          <div
            class="flex flex-col items-center lg:flex-row lg:space-x-10 xl:space-x-12 xxl:space-x-20 lg:items-start justify-center"
          >
            <Images :variation="variation" class="w-full" />
            <div class="max-w-md">
              <div class="flex items-end">
                <h4 v-text="product.name" class="font-medium dark:text-white" />
                <p
                  v-if="product.discontinued || (variation && variation.discontinued)"
                  class="italic text-xs text-red-400 ml-2 leading-8"
                  v-text="`(${$t('product.discontinued')})`"
                />
              </div>
              <div style="min-height: 36px" class="mb-4 flex items-end mt-1">
                <ProductPrice
                  v-if="variation"
                  :msrp="variation.msrp"
                  :currency="$store.state.store.currency"
                  :discount="variation.discount"
                  :showWithVat="$store.state.store.showWithVat"
                  :quantity="1"
                  :horizontal="true"
                  size="large"
                  :showVatText="true"
                  class="dark:text-gray-100"
                />
                <NotAvailable
                  v-else
                  :selectedAttributes="selectedAttributes"
                  :productName="product.name"
                  :query="query"
                />
              </div>
              <Description :product="product" :variation="variation" />
              <div class="border-t my-4" />
              <Attributes
                :productName="productName"
                :variations="variations"
                :selectedAttributes="selectedAttributes"
              />
              <div class="flex justify-between items-center text-gray-500 dark:text-gray-100">
                <div class="mr-2" v-text="$t('store.items.quantity')" />
                <div class="flex justify-between items-center w-48 border">
                  <button
                    class="px-3 py-3 outline-none focus:outline-none"
                    @click="quantity > 1 ? (quantity -= 1) : null"
                    aria-label="decrease"
                  >
                    <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
                      <path d="M19,13H5V11H19V13Z" />
                    </svg>
                  </button>
                  <div class="w-16 text-center" v-text="quantity" />
                  <button
                    class="px-3 py-3 outline-none focus:outline-none"
                    @click="quantity += 1"
                    aria-label="increase"
                  >
                    <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
                      <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div class="flex flex-col align-end items-end mt-2 mb-3">
                <div v-if="variation">
                  <Inventory
                    :variation="variation"
                    :qtyIncCart="qtyIncCart"
                    :key="variation.id"
                    show-details
                    show-notify
                    class="dark:text-gray-100"
                  />
                  <div class="text-xs text-right text-gray-500" v-text="`SKU: ${variation.sku}`" />
                </div>
                <NotAvailable
                  v-else
                  :showSuggest="true"
                  :selectedAttributes="selectedAttributes"
                  :productName="product.name"
                  :query="query"
                  class="mt-2 flex-shrink-0"
                />
              </div>
              <AddToCart
                :variation="variation"
                :quantity="qtyIncCart"
                :large="true"
                @addedToCart="quantity = 1"
              />
              <p
                v-if="product.discontinued || (variation && variation.discontinued)"
                class="italic text-sm mt-2 text-right text-red-400"
                v-text="$t('product.discontinuedLong')"
              />
            </div>
          </div>

          <InfoBoxes v-if="variation && variation.type === 'filament'" />
          <div
            class="mx-auto lg:max-screen-md xl:max-w-screen-lg xxl:max-w-screen-xl bg-transparent"
          >
            <Accordions :product="product" :variation="variation" :key="product.id" />
          </div>
          <!-- <div v-if="product.video" class="flex justify-center mt-6 lg:mt-12 mb-6 sm:mb-12"> -->
          <!-- <YoutubeVideo :videoId="product.video" /> -->
          <!-- </div> -->
          <reviews class="mt-12" />
        </div>

        <instagram class="mt-12" />
        <SuggestionBox class="my-12" />
      </div>
    </div>
  </article>
</template>
<script>
import Breadcrumb from "./Breadcrumb.vue";
import InfoBoxes from "./InfoBoxes.vue";
import Description from "./Description.vue";
import ProductPrice from "../store/ProductPrice.vue";
import Attributes from "./Attributes.vue";
import Images from "./Images.vue";
import Inventory from "../store/Inventory.vue";
import AddToCart from "../store/AddToCart.vue";
import NotAvailable from "./NotAvailable.vue";
import Accordions from "./Accordions.vue";
import YoutubeVideo from "../shared/YoutubeVideo.vue";
import NotFound from "../shared/NotFound.vue";
import SuggestionBox from "../shared/SuggestionBox.vue";
import Variation from "@/services/variation2.js";
import Reviews from "../shared/Reviews.vue";
import Instagram from "../shared/Instagram.vue";
import imgixClient from "@/services/imgixClient";

export default {
  name: "Product",
  components: {
    Breadcrumb,
    Description,
    ProductPrice,
    Attributes,
    Images,
    Inventory,
    AddToCart,
    NotAvailable,
    Accordions,
    // YoutubeVideo,
    InfoBoxes,
    NotFound,
    SuggestionBox,
    Reviews,
    Instagram,
  },
  props: {
    productName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
    query: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: false,
      loading: false,
      product: null,
      variations: [],
      quantity: 0,
    };
  },
  computed: {
    variation() {
      return this.variations.find(({ name }) => name === this.name);
    },
    possibleAttributes() {
      return [
        ...new Set(
          this.variations.reduce(
            (c, { attributes }) => [
              ...c,
              ...Object.entries(attributes || {})
                .filter(([_k, v]) => v)
                .map(([k]) => k),
            ],
            []
          )
        ),
      ];
    },
    selectedAttributes() {
      return Object.entries(this.variation ? this.variation.attributes : this.query).reduce(
        (c, [attribute, value]) => {
          if (this.possibleAttributes.includes(attribute)) {
            c[attribute] = value.toString();
          }
          return c;
        },
        {}
      );
    },
    qtyIncCart() {
      return (
        this.quantity +
        this.$store.getters["cart/qtySkuInCart"](this.variation ? this.variation.sku : null)
      );
    },
  },
  methods: {
    sendAnalytics() {
      this.$store.dispatch("shared/sendAnalytics", {
        event: "view_item",
        value: this.variation.price / 100,
        items: [this.variation.googleItem()],
      });
    },
    fetchProductAndVariations(productName) {
      this.product = this.$store.state.content.products.find((i) => i.name === productName);
      this.variations = this.$store.state.content.variations
        .filter((i) => i.product.name === this.productName)
        .map((v) => new Variation(this.$store, v));
      if (!this.product || !this.variations.length) {
        this.loading = true;
        this.product = null;
        this.variations.splice(0, this.variations.length);
        Promise.all([
          this.$api2
            .get(`catalog/products/${productName}`, {
              params: {
                language: process.env.VUE_APP_LANGUAGE,
                name: productName,
              },
            })
            .then(({ data }) => data),
          this.$api2
            .get("catalog/variations", {
              params: {
                language: process.env.VUE_APP_LANGUAGE,
                productName,
              },
            })
            .then(({ data }) => data),
        ])
          .then(([product, variations]) => {
            if (!product) throw new Error(`${productName} not found`);
            if (!variations.length) throw new Error(`No variations found for ${productName}`);
            this.product = product;
            this.variations.push(...variations.map((v) => new Variation(this.$store, v)));
          })
          .catch((error) => {
            this.error = true;
            throw error;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateQuantity() {
      this.variation.quantity = this.qtyIncCart;
    },
  },
  watch: {
    productName: {
      immediate: true,
      handler: "fetchProductAndVariations",
    },
    variation: {
      immediate: true,
      handler(variation) {
        if (variation) {
          variation.getAvailability();
          this.quantity = 1;
          this.sendAnalytics();
        }
      },
    },
    qtyIncCart: "updateQuantity",
  },
  metaInfo() {
    if (!this.variation) return {};
    const meta = {
      title: this.variation.texts.title,
      meta: [
        {
          vmid: "ogtitle",
          name: "og:title",
          property: "og:title",
          content: `${this.variation.name} - add:north`,
        },
        {
          vmid: "ogimagealt",
          name: "og:image:alt",
          property: "og:image:alt",
          content: `${this.variation.name} - add:north`,
        },
        {
          vmid: "twittertitle",
          name: "twitter:title",
          property: "twitter:title",
          content: `${this.variation.name} - add:north`,
        },
        {
          vmid: "description",
          name: "description",
          content: this.variation.description.replace(/<\/?[^>]+(>|$)/g, ""),
        },
        {
          vmid: "ogdescription",
          name: "og:description",
          property: "og:description",
          content: this.variation.description.replace(/<\/?[^>]+(>|$)/g, ""),
        },
        {
          vmid: "twitterdescription",
          name: "twitter:description",
          property: "twitter:description",
          content: this.variation.description.replace(/<\/?[^>]+(>|$)/g, ""),
        },
      ],
    };
    meta.meta.push(
      {
        vmid: "ogimage",
        name: "og:image",
        property: "og:image",
        content: imgixClient.buildURL(this.variation.thumbnail.src, {
          auto: "format,compress",
          ch: "DPR",
          q: 45,
          w: 320,
          fit: "clip",
        }),
      },
      {
        vmid: "twitterimage",
        name: "twitter:image",
        property: "twitter:image",
        content: imgixClient.buildURL(this.variation.thumbnail.src, {
          auto: "format,compress",
          ch: "DPR",
          q: 45,
          w: 320,
          fit: "clip",
        }),
      }
    );
    return meta;
  },
  jsonld() {
    if (!this.variation) return {};
    return this.variation.jsonld;
  },
};
</script>
